@import "main";

.thanks {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  align-self: center;
  max-width: 100% !important;

  .no-br {
    white-space: nowrap;
  }

  &__wrapper {
    width: 810px;
    background: #fff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    padding: 50px;
  }

  &__image {
    display: flex;
    align-items: flex-end;
    width: 50%;
  }

  &__text {
    padding-top: 50px;
    margin-left: 40px;
    width: 50%;
    line-height: 24px;

    h1 {
      line-height: 37px;
      text-align: left;
    }

    div {
      align-self: center;
    }
  }
}

/* lg-размер (<=1199px) */
@media (max-width: 1199px) {
  /* CSS для ширины от 992px до 1199px */
}

/* md-размер (<=991px) */
@media (max-width: 991px) {
  /* CSS для ширины от 768px до 991px */
  .thanks {
    &__wrapper {
      width: auto;
      margin: 50px 20px;
    }

    &__text {
      padding-top: 0;
    }
  }
}

/* sm-размер (<=768px) */
@media (max-width: 767px) {
}

/* xs-размер (<=575px) */

@media (max-width: 575px) {
  .thanks {
    &__wrapper {
      width: auto;
      margin: 10px 0;
      padding: 0;
      flex-direction: column;
    }

    &__image {
      padding: 20px 20px 0 20px;
      width: 100%;
    }

    &__text {
      width: 100%;
      padding: 0 20px 20px 20px;
      margin: 0;
    }
  }
  .button {
    &__wrapper {
      display: flex;
      justify-content: center;
    }
  }
}

@media (max-width: 320px) {

}

