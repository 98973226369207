.bg__wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  height: 100%;
  //  padding-bottom: 100px;
  //  background-image: url(/static/media/backgraund.f6e72ef4.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.ao_medicina {
  justify-content: flex-start;

  .header {
    &__wrapper {
      height: 105px;
    }
  }

  .container {
    //max-width: none;
  }

  .bg {
    &__wrapper {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;

      min-height: 100%;
      height: 100%;
      padding-bottom: 100px;

      background-image: url("../images/templates/ao_medicina/backgraund.png");
      background-repeat: no-repeat;
      background-position: center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }

  .header {
    &__wrapper {
      border-bottom: 0;
    }
  }

  .footer {
    &__wrapper {
      height: 350px;
      width: 100%;
      background: none;
    }

    &__fake-block {
      height: 100px;
      width: 100%;
      background-color: transparent;
    }
  }

  .form {
    &__header {
      padding: 40px;
      background: #FFFFFF;
      box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.13);
      border-radius: 5px;
      position: relative;
    }

    &__container {

      .adplatforms {
        .item {
          &:hover {
            border: 1px solid #2862FF !important;
          }
        }
      }

      .variants {
        .item {
          &:hover {
            border: 1px solid #2862FF;
          }
        }
      }
    }

  }

  .rating {
    .pi-star {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='29' viewBox='0 0 32 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.1543 0L20.2171 10.4081L31.3712 11.0557L22.728 18.1359L25.5589 28.9443L16.1543 22.912L6.74973 28.9443L9.58059 18.1359L0.937392 11.0557L12.0915 10.4081L16.1543 0Z' fill='%232862FF'/%3E%3C/svg%3E") !important;

      }
    }

    &:before {
      background-image: url("data:image/svg+xml,%3Csvg width='31' height='29' viewBox='0 0 31 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.6152 0L19.678 10.4081L30.8321 11.0557L22.1889 18.1359L25.0198 28.9443L15.6152 22.912L6.21067 28.9443L9.04153 18.1359L0.39833 11.0557L11.5525 10.4081L15.6152 0Z' fill='%23C4C4C4'/%3E%3C/svg%3E") !important;
    }

  }

  .nps .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background-color: transparent;
    border: 1px solid #2862FF;
    color: #373947;
  }

  .nps .p-selectbutton .p-button.p-highlight {
    background-color: #2862FF !important;
    border: 1px solid #2862FF !important;
    color: #FFFFFF !important;
  }

  .button {
    &__wrapper {
      .p-button {
        background-color: #2862FF;
        border: 1px solid #2862FF;

        &:hover {
          background-color: #2862FF !important;
          border: 1px solid #2862FF !important;

        }
      }
    }
  }

  @media (max-width: 767px) {
    .header {
      &__subtext {
        text-align: center !important;
      }
    }
    .form {
      &__header, &__container {
        text-align: center;
      }

      &__container {
      }
    }
    .element {
      &__wrapper {
      }
    }

    .nps {
      margin: 0 auto;
    }
    .variants {
      flex-direction: column;

      .item {
        width: 100% !important;
      }
    }

    .thanks {
      &__text {
        h1 {
          text-align: center;
        }

        text-align: center;
      }
    }

  }

}

.avtoset_rf {

  .container {
    margin-top:0;
  }

  .header {
    &__wrapper {
      padding-top: 100px;
      height: auto;
      border-bottom: 0;
      background-color: transparent;
      justify-content: center;

      .header {
        &__logo {
          padding: 0;
        }
      }
    }
  }

  .form {
    &__container {
      border-radius: 25px;
      .textarea {
        &__wrapper {
          border-radius: 10px;
          textarea {
            border-radius: 10px;
          }
        }
      }
    }
    &__header {

    }
  }

  .footer {
    margin-top: -150px;

    &__wrapper {
      height: 380px;
      width: 100%;
      background: url("../images/templates/avtoset_rf/promo_block_bg.svg") 50% 100% no-repeat;
      position: relative;

      .img-left-car {
        position: absolute;
        width: 300px;
        left: 50px;
        bottom: -50px;
      }

      .img-right-car {
        position: absolute;
        width: 338px;
        right: 50px;
        bottom: -50px;
      }
    }

    &__fake-block {
      background-color: transparent;
    }
  }

  .rating {
    .pi-star {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='29' viewBox='0 0 32 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.1543 0L20.2171 10.4081L31.3712 11.0557L22.728 18.1359L25.5589 28.9443L16.1543 22.912L6.74973 28.9443L9.58059 18.1359L0.937392 11.0557L12.0915 10.4081L16.1543 0Z' fill='%23E32136'/%3E%3C/svg%3E") !important;

      }
    }

    &:before {
      background-image: url("data:image/svg+xml,%3Csvg width='31' height='29' viewBox='0 0 31 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.6152 0L19.678 10.4081L30.8321 11.0557L22.1889 18.1359L25.0198 28.9443L15.6152 22.912L6.21067 28.9443L9.04153 18.1359L0.39833 11.0557L11.5525 10.4081L15.6152 0Z' fill='%23C4C4C4'/%3E%3C/svg%3E") !important;
    }

  }
  .nps .p-selectbutton .p-button{
    padding: 0;
  }

  .nps .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background-color: transparent;
    border: 1px solid #E32136;
    color: #E32136;
  }

  .nps .p-selectbutton .p-button.p-highlight {

    background-color: #E32136 !important;
    border: 1px solid #E32136 !important;
    color: #FFFFFF !important;
  }

  .button {
    &__wrapper {
      .p-button {
        background-color: #E32136;
        border: 1px solid #E32136;
        border-radius: 10px;
        &:hover {
          background-color: #E32136 !important;
          border: 1px solid #E32136 !important;

        }
      }
    }
  }

  @media (max-width: 767px) {


    .nps {
      width: 250px;
    }
    .nps .p-selectbutton .p-button{
      width: 40px;
      height: 40px;
      padding: 0;
    }

    .nps .p-selectbutton .p-button.p-highlight {
      background-color: #E32136 !important;
      border: 1px solid #E32136 !important;
      color: #FFFFFF !important;
    }

    .footer {
      margin-top: -150px;

      &__wrapper {
        .img-left-car {
          display: none;
        }

        .img-right-car {
          position: absolute;
          width: 230px;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          bottom: -30px;
        }
      }

      &__fake-block {
        background-color: transparent;
      }
    }
  }
}


/*
.p-rating .p-rating-icon.pi-star {
  color: #2862FF!important;
}
*/
